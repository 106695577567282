<template>
  <CustomDialog :open.sync="open"
                title="SMS/MMS Viewer"
                @click-close="close_dialog" @button1="close_dialog"
                :max-width="mdAndDown ? `100%` : 1024"
                ref="dialog" :has-button2="false" button1-text="Close"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text class="py-0" v-if="item && campaign">
          <v-simple-table class="border-color--grey border-1 mb-1">
            <template v-slot:default>
              <tbody>
              <tr>
                <td>Campaign</td>
                <td>{{ campaign.name | ucwords }}</td>
              </tr>
              <tr>
                <td>Message Type</td>
                <td>{{ item.type.toUpperCase() }}</td>
              </tr>
              <tr>
                <td>Destination Number</td>
                <td>{{ item.destination_number }}</td>
              </tr>
              <tr v-if="item.sent_on && item.source_number">
                <td>Source Number</td>
                <td>{{ item.source_number }}</td>
              </tr>
              <tr>
                <td>Country Code</td>
                <td>{{ item.country_code || '' }}</td>
              </tr>
              <tr>
                <td>Created At</td>
                <td>{{ item.created_at | format('LLL') }}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{{ item.status | ucwords }}</td>
              </tr>
              <tr>
                <td>SMS/MMS Period</td>
                <td>{{ item.sent_from | format('LLL') }} - {{ item.sent_until | format('LLL') }}</td>
              </tr>
              <tr>
                <td>SMS/MMS Sent On</td>
                <td>{{ item.sent_on ? item.sent_on : '' | format('LLL') }}</td>
              </tr>
              <tr>
                <td>SMS/MMS Body</td>
                <td>{{ item.message || '' }}</td>
              </tr>
              <tr v-if="item.type === 'mms'">
                <td>Attachment</td>
                <td v-if="item.props.attachment">{{ item.props.attachment.filename || '' }}</td>
                <td v-else>No Attachment</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="mt-2 fullwidth" v-if="item.replies.length">
            <h4 class="subtitle-1">Replies</h4>
            <v-expansion-panels flat multiple class="border-color--grey border-1 rounded-10">
              <v-expansion-panel v-for="reply in item.replies" :key="reply.id">
                <v-expansion-panel-header>{{ reply.created_at | format('lll') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ reply.request.Body || 'Message cannot be display' }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue"; 

export default {
  name: "SMSView",
  components: {CustomDialog}, 
  props: {
    item: Object,
    campaign: Object,
    dialog: {type: Boolean, default: false}
  },
  data: () => ({
    open: false
  }),
  watch: {
    dialog(val) {
      this.open = val
    },
    open(val) {
      this.$emit('update:dialog', val)
    }
  },
  methods: {
    close_dialog() {
      this.open = false;
    }
  }
}
</script>

<style scoped lang="scss">
 

.iframe {
  width: 100% !important;
  min-height: 98vh;
  border: none;
}
</style>