<template>
  <v-card
    min-height="100vh"
    elevation="3"
    class="rounded-10 overflow-hidden campaign-smsmms"
  >
    <VueTable
      :items="items"
      :headers="headers"
      :show-row-actions="true"
      @load-more="loadMoreEmails"
      :show-select="false"
      icon="mdi-alpha-s-box-outline"
      :key="componentKey"
      :has-header="false"
      :no-more-data="!has_more_items"
      empty-text="No SMS/MMS yet"
      :loading="loading_items"
    >
      <template v-slot:header-left-toolbar>
        <h1 class="fs-20 my-2 fw-900 primary--text">
          {{ `SMS/MMS Logs : ${(campaign && campaign.name) || ''}` }}
        </h1>
      </template>
      <template v-slot:header-toolbar>
        <v-row dense align="center" justify="end">
          <v-btn
            color="primary"
            class="text-none"
            depressed
            @click="
              $router.push({ name: 'campaigns', params: { type: 'sms' } })
            "
          >
            <v-icon left>mdi-arrow-left-bold-circle-outline</v-icon>
            Back
          </v-btn>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                color="primary"
                class="ml-1 text-none"
                v-bind="attrs"
                v-on="on"
              >
                Status: {{ filters.status | ucwords }}
                <v-icon right>mdi-menu-swap</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="item in message_statuses"
                :key="item"
                @click="filters.status = item"
              >
                <v-list-item-title class="subtitle-2 primary--text">
                  {{ item | ucwords }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
      <template v-slot:row-slot="{ item }">
        <td :id="`smsmms-${item.id}`">{{ item.id }}</td>
        <td>{{ item.destination_number }}</td>
        <td>{{ item.type.toUpperCase() }}</td>
        <td class="text-center">{{ item.replies.length || 0 }}</td>
        <td class="text-center">{{ item.status | ucwords }}</td>
        <td class="text-center">{{ item.created_at | format('lll') }}</td>
        <ActionDropdown
          :item="item"
          :has-edit="false"
          :has-delete="true"
          :has-view="true"
          @view="action_view_dialog(true, item)"
          @delete="action_confirm_dialog(true, item)"
        >
        </ActionDropdown>
      </template>
    </VueTable>
    <DeleteDialog
      :open-dialog.sync="confirm_dialog"
      title="Delete Email"
      text-content="Are you sure you want to delete this message?"
      @delete="handleDeleteMessage"
      delete-button-text="Yes! Delete Message"
    ></DeleteDialog>
    <SMSView
      :dialog.sync="view_dialog"
      :campaign="campaign"
      :item="activeItem"
    ></SMSView>
  </v-card>
</template>

<script>
import VueTable from '@/common/VueTable/VueTable.vue'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import http_build_query from 'js-http-build-query'
import SMSView from '@/modules/Campaign/components/SMSView'
import request from '@/services/axios_instance'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CampaignSMSMMS',
  components: { SMSView, DeleteDialog, ActionDropdown, VueTable },
  mixins: [fetch_services],
  data: () => ({
    componentKey: 0,
    headers: [
      { text: 'ID', value: 'id', sortable: true, align: 'left', width: 70 },
      {
        text: 'To',
        value: 'destination_email',
        sortable: true,
        align: 'left',
        width: 220
      },
      {
        text: 'Type',
        value: 'message_type',
        sortable: true,
        align: 'left',
        width: 40
      },
      { text: 'Replies', sortable: false, align: 'center', width: 60 },
      {
        text: 'Status',
        value: 'status',
        sortable: true,
        align: 'center',
        width: 60
      },
      {
        text: 'Created',
        value: 'created_at',
        sortable: true,
        align: 'center',
        width: 200
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'center',
        width: 40
      }
    ],
    filters: {
      status: 'all'
    },
    campaign_id: null,
    message_statuses: [
      'all',
      'sent',
      'not_sent',
      'failed',
      'delivered',
      'undelivered',
      'accepted',
      'queued',
      'sending',
      'delivery_unknown'
    ]
  }),
  watch: {
    '$route.params': {
      handler: function (val) {
        this.campaign_id = val.id
      },
      immediate: true
    },
    filters: {
      handler: function (val) {
        this.loadEmails()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters('campaigns', ['campaign'])
  },
  created() {
    if (this.campaign_id) {
      this.loadEmails()
      this.fetchCampaign({ id: this.campaign_id })
    }
  },
  methods: {
    ...mapActions('campaigns', ['fetchCampaign']),
    loadEmails() {
      this.loadItems(
        `api/lead-campaign/sms/sms-mms/${this.campaign_id}?${http_build_query(
          this.filters
        )}`,
        true
      )
    },
    loadMoreEmails() {
      this.loadMoreItems()
    },
    handleDeleteMessage() {
      this.deleteItem(
        `api/lead-campaign/sms/sms-mms/${this.campaign_id}/${this.activeItem.id}`,
        'Message deleted',
        this.action_confirm_dialog(false, null),
        null
      )
    }
  }
}
</script>

<style scoped lang="scss"></style>
